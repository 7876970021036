import React, { useState, useEffect } from 'react';
import { Button, Navbar, Nav, Form } from 'react-bootstrap';
import Web3 from 'web3';
import { Web3Modal } from '@web3modal/react';
import './App.css';

const networks = [
  { name: 'Mainnet', id: 'mainnet' },
  { name: 'Optimism', id: 'optimism' },
  { name: 'Ethereum', id: 'ethereum' },
  { name: 'Binance', id: 'binance' },
  { name: 'Avalanche', id: 'avalanche' },
  { name: 'Fantom', id: 'fantom' },
  { name: 'Matic', id: 'matic' }
];

function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0]);

  useEffect(() => {

    async function getAccounts() {
      try {
        const accounts = await web3.eth.getAccounts();
        setAccounts(accounts);
      } catch (error) {
        console.error(error);
      }
    }

    if (web3) {
      getAccounts();
    }
  }, [web3]);

  function toggleNavbar() {
    setIsExpanded(prevState => !prevState);
  }

  function handleNetworkChange(event) {
    const newNetwork = networks.find(network => network.id === event.target.value);
    setSelectedNetwork(newNetwork);
    Web3Modal.clearCachedProvider();
  }

  async function connectToWallet() {
    try {
      const provider = await Web3Modal.connect();
      setWeb3(new Web3(provider));
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Navbar bg="light" expand="lg" expanded={isExpanded} onToggle={toggleNavbar} className="sticky-top">
      <Navbar.Brand href="#">My App</Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={toggleNavbar}
        className={isExpanded ? 'collapsed' : ''}
      >
        {isExpanded ? (
          <i className="fas fa-times" />
        ) : (
          <>
            <span />
            <span />
            <span />
          </>
        )}
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {/* Other nav items go here */}
        </Nav>
        <Nav>
          <Form inline>
            <Form.Control
              as="select"
              custom
              value={selectedNetwork.id}
              onChange={handleNetworkChange}
            >
              {networks.map(network => (
                <option key={network.id} value={network.id}>
                  {network.name}
                </option>
              ))}
            </Form.Control>
          </Form>
          {accounts ? (
            <span>{accounts[0].slice(0, 4)}...{accounts[0].slice(-4)}</span>
          ) : (
            <Button variant="primary" onClick={connectToWallet}>
              Connect
            </Button>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default App;


